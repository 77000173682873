import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cart from "components/layout/Cart";
import SignIn from "pages/User/Profile/LogIn";
import RootRouter from "routes/RootRouter";
import AppContextProvider from "./contexts/appcontext";
import Slide from "./components/layout/SizeChart";
import Alert from "components/basic/Alert";
import { getProducts } from "redux/action/productAction";
import { getCart } from "redux/action/cartAction";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getCart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { show } = useSelector(({ alert }) => alert);
  return (
    <AppContextProvider>
      <SignIn />
      <Cart />
      <Slide />
      <div className="h-screen w-screen">
        {show && <Alert />}
        <RootRouter />
      </div>
    </AppContextProvider>
  );
}

export default App;
