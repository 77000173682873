import React, { Fragment } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import { MenuIcon, ShoppingBagIcon, XIcon } from "@heroicons/react/outline";
import Flag from "assets/images/flag.webp";
import Logo from "assets/images/StyleAfrica.jpg";
import { useAppContext } from "contexts/appcontext";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const TopNav = () => {
  const { pathname } = useLocation();
  const { setOpenSignin, setOpenCart, open, setOpen } = useAppContext();

  const { user: currentUser } = useSelector(({ user }) => user);
  const { tempCart } = useSelector(({ cart }) => cart);

  const navigation = () => {
    if (currentUser.role === "admin") {
      return {
        pages: [
          { name: "View All", href: "/all" },
          { name: "Admin", href: "/admin" },
        ],
      };
    }
    return {
      pages: [
        { name: "View All", href: "/all" },
      ],
    };
  };

  return (
    <div className="bg-white fixed left-0 rigth-0 w-full z-10">
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pt-5 pb-2">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                  {navigation().pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <a
                        className="-m-2 block p-2 font-medium text-gray-900 active:border-b active:border-emerald-800"
                        href={page.href}
                      >
                        {page.name}
                      </a>
                    </div>
                  ))}
                </div>

                <div
                  onClick={() => {
                    setOpenSignin(true)
                    setOpen(false)
                  }}
                  className="cursor-pointer space-y-6 border-t border-gray-200 py-6 px-4"
                >
                  <div className="flow-root">
                    <span>
                      {currentUser?.email
                        ? `Hi ${currentUser?.name}`
                        : "Sign In"}
                    </span>
                  </div>
                </div>

                <div className="border-t border-gray-200 py-6 px-4">
                  <div className="-m-2 flex items-center p-2">
                    <img
                      src={Flag}
                      alt=""
                      className="block h-auto w-5 flex-shrink-0"
                    />
                    <span className="ml-3 block text-base font-medium text-gray-900">
                      NGN
                    </span>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header className="z-30 bg-white">
        <nav
          aria-label="Top"
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 z-50"
        >
          <div className="border-b border-gray-200">
            <div className="flex h-16 items-center">
              <button
                type="button"
                className="rounded-md bg-white p-2 text-gray-400 lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="ml-4 flex lg:ml-0">
                <a href="/">
                  <span className="sr-only">Your Company</span>
                  <img className="w-16 lg:w-32 lg:-mb-10" src={Logo} alt="" />
                </a>
              </div>

              {/* Flyout menus */}
              <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch">
                <div className="flex h-full space-x-8">
                  {navigation().pages.map((page) => (
                    <a
                      key={page.name}
                      className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                      href={page.href}
                    >
                      {page.name}
                    </a>
                  ))}
                </div>
              </Popover.Group>

              <div className="ml-auto flex items-center">
                <div
                  onClick={() => setOpenSignin(true)}
                  className="cursor-pointer hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6"
                >
                  <div className="text-sm font-medium text-gray-700 hover:text-gray-800">
                    {currentUser?.email ? `Hi ${currentUser?.name}` : "Sign In"}
                  </div>
                </div>

                <div className="hidden lg:ml-8 lg:flex">
                  <div className="flex items-center text-gray-700 hover:text-gray-800">
                    <img
                      src={Flag}
                      alt=""
                      className="block h-4 w-5 flex-shrink-0"
                    />
                    <span className="ml-3 block text-sm font-medium">NGN</span>
                  </div>
                </div>

                {/* Cart */}
                {!pathname.includes("checkout") && (
                  <div className="ml-4 flow-root lg:ml-6">
                    <button
                      onClick={() => setOpenCart(true)}
                      className="group -m-2 flex items-center p-2"
                    >
                      <ShoppingBagIcon
                        className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                        {tempCart.products ? tempCart.products.length : 0}
                      </span>
                      <span className="sr-only">items in cart, view bag</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default TopNav;
