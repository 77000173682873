import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useAppContext } from "contexts/appcontext";

const sizes = [
  { size: 6, bust: "36", waist: "27.5", hips: "38.5" },
  { size: 8, bust: "38", waist: "29", hips: "41" },
  { size: 10, bust: "40", waist: "30.5", hips: "43" },
  { size: 12, bust: "42", waist: "32", hips: "45" },
  { size: 14, bust: "44", waist: "34", hips: "47" },
  { size: 16, bust: "46", waist: "36", hips: "48.5" },
  { size: 18, bust: "48.5", waist: "37.5", hips: "49.5" },
  { size: 20, bust: "49.5", waist: "38.5", hips: "50.5" },
  { size: 22, bust: "50", waist: "39.5", hips: "52" },
  { size: 24, bust: "52", waist: "41", hips: "54" },
];


const SizeTable = () => {
  return (
    <div className="">
    <div className="flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
           <p className="text-sm">
            ps. if you're unsure, of your size reach us on Whatsapp or instagram
          </p> <br />
          <strong className="text-lg">Women</strong>
          <div className="overflow-hidden mt-4 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    UK Size
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Bust
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Waist
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Hip
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {sizes.map((size, idx) => (
                  <tr
                    key={size.size}
                    className={idx % 2 === 0 ? undefined : "bg-gray-50"}
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {size.size}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {size.bust}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {size.waist}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {size.hips}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

const Slide = () => {
  const { openSizeGuide, setOpenSizeGuide } = useAppContext();

  return (
    <Transition.Root show={openSizeGuide} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpenSizeGuide}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Size Guide
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                            onClick={() => setOpenSizeGuide(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6 overflow-y-scroll">
                      {/* Replace with your content */}
                      <SizeTable />
                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Slide;
