import React from "react";
import WhatsAppIcon from "assets/icons/Whatsapp";
import InstagramIcon from "assets/icons/Instagram";

const Socials = () => {
  return (
    <div className="fixed w-screen shadow-2xl bottom-0 py-5 md:pb-5 pl-2 bg-white z-40">
      <div className="w-full flex items-center justify-center space-x-5">
        <a
          href="https://wa.me/2348126243704"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-100 flex items-center"
        >
          <WhatsAppIcon width="18" height="18" />
          <em className="text-gray-700 ml-1">Let's chat</em>
        </a>
        <a
          href="https://www.instagram.com/style__africa"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-100 flex items-center"
        >
          <InstagramIcon width="18" height="18" />
          <em className="text-gray-700 ml-1">Visit us</em>
        </a>
      </div>
    </div>
  );
};

export default Socials;
