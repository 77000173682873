import {
  ExclamationCircleIcon,
  XCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HIDE_ALERT } from "redux/reducer/alert";

const Alert = () => {
  const targetRef = useRef(null);
  const { heading, body, type } = useSelector(({ alert }) => alert);
  const dispatch = useDispatch();

  useEffect(() => {
    function handleClickOutside(event) {
      if (targetRef.current && !targetRef.current.contains(event.target)) {
        dispatch({ type: HIDE_ALERT });
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetRef]);

  const shifts = {
    success: {
      bg: "bg-emerald-50",
      heading: "text-emerald-800",
      body: "text-emerald-700",
      icon: (
        <CheckCircleIcon
          className="h-5 w-5 text-emerald-400"
          aria-hidden="true"
        />
      ),
    },
    error: {
      bg: "bg-red-50",
      heading: "text-red-800",
      body: " text-red-700",
      icon: <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />,
    },
    info: {
      bg: "bg-red-50",
      heading: "text-red-700",
      body: "text-emerald-700",
      icon: (
        <InformationCircleIcon
          className="h-5 w-5 text-blue-400"
          aria-hidden="true"
        />
      ),
    },
    warning: {
      bg: "bg-yellow-50",
      heading: "text-yellow-800",
      body: "text-yellow-700",
      icon: (
        <ExclamationCircleIcon
          className="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
      ),
    },
  };

  return (
    <div ref={targetRef} className="fixed w-3/4 md:w-1/2 left-1/2 transform -translate-x-1/2 justify-center z-50">
      <div className={`rounded-md ${shifts[type].bg} p-4 w-full relative z-50`}>
        <div className="flex">
          <div className="flex-shrink-0">{shifts[type].icon}</div>
          <div className="ml-3">
            <h3 className={`text-sm font-medium ${shifts[type].heading}`}>
              {heading}
            </h3>
            <div className={`mt-2 text-sm ${shifts[type].body}`}>
              {typeof body === "string"
                ? body
                : body?.map((error, index) => <li key={index}>{error}</li>)}
            </div>
            <button
              className={`mt-3 ${shifts[type].heading}`}
              onClick={() => dispatch({ type: HIDE_ALERT })}
            >
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
