import http from "plugin/http.client";
import { SHOW_ALERT } from "redux/reducer/alert";
import {
  ADD_TO_CART,
  CREATE_ORDER_SUCCESS,
  RESET_CART,
  ADD_TO_TEMP_CART,
  GET_CART,
  CLEAR_TEMP_CART,
  LOADING_CART,
  CREATING_CART,
  REMOVING_FROM_CART,
  REMOVE_FROM_CART,
} from "redux/reducer/cart";
import { handleError } from "utils/handleError";

const calculatePrice = (productArray) => {
  let total = 0;
  productArray?.forEach((product) => {
    if (product.price) {
      total = total + parseFloat(product.price);
    }
  });
  return total;
};

export const addToCart = (productId, data) => async (dispatch) => {
  try {
    const tempCart = localStorage.getItem("tempCart")
      ? JSON.parse(localStorage.getItem("tempCart"))
      : {};
    const products = tempCart.products || [];

    products.push({ ...data, productId, index: products.length });
    tempCart["products"] = products;
    tempCart["total"] = calculatePrice(products);

    localStorage.setItem("tempCart", JSON.stringify(tempCart));
    dispatch({ type: ADD_TO_TEMP_CART, payload: tempCart });

    dispatch({
      type: SHOW_ALERT,
      payload: {
        heading: "Yay! we're glad you like something",
        body: "Item added to cart successfully",
        type: "success",
      },
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const removeFeomTempCart = (index) => async (dispatch) => {
  try {
    const tempCart = JSON.parse(localStorage.getItem("tempCart"));
    const newCartProducts = tempCart.products.filter(
      (product) => product.index !== index
    );
    const total = newCartProducts.length ? calculatePrice(newCartProducts) : 0;
    tempCart.products = newCartProducts;
    tempCart.total = total;

    localStorage.setItem("tempCart", JSON.stringify(tempCart));
    dispatch({ type: ADD_TO_TEMP_CART, payload: tempCart });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const addItemToCart = (productId, data) => async (dispatch) => {
  try {
    const tempCart = localStorage.getItem("tempCart")
      ? JSON.parse(localStorage.getItem("tempCart"))
      : {};
    const products = tempCart.products || [];

    products.push({ ...data, product: productId });
    tempCart["products"] = products;
    tempCart["total"] = calculatePrice(products);

    localStorage.setItem("tempCart", JSON.stringify(tempCart));
    dispatch({ type: ADD_TO_TEMP_CART, payload: tempCart });

    dispatch({
      type: SHOW_ALERT,
      payload: {
        heading: "Yay! we're glad you like something",
        body: "Item added to cart successfully",
        type: "success",
      },
    });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const getCart = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (!token) return;
  dispatch({ type: LOADING_CART, payload: true });

  try {
    const res = await http.get("cart");
    const cart = res.data;

    cart["total"] = calculatePrice(
      cart?.products?.map((product) => product.product)
    );

    dispatch({ type: GET_CART, payload: res.data });
    dispatch({ type: LOADING_CART, payload: false });
  } catch (error) {
    dispatch({ type: LOADING_CART, payload: false });
    handleError(error, dispatch);
  }
};

export const getCartOnLogin = () => async (dispatch) => {
  const tempCart = localStorage.getItem("tempCart")
    ? JSON.parse(localStorage.getItem("tempCart"))
    : {};
  if (!tempCart?.products?.length) {
    return dispatch(getCart());
  }
  try {
    dispatch({ type: LOADING_CART, payload: true });
    const res = await http.post("cart/add", tempCart.products);
    const cart = res.data;

    localStorage.setItem("tempCart", JSON.stringify({}));

    cart["total"] = calculatePrice(
      cart.products.map((product) => product.product)
    );
    dispatch({ type: ADD_TO_CART, payload: cart });
    dispatch({ type: CLEAR_TEMP_CART });
    dispatch({ type: LOADING_CART, payload: false });
  } catch (error) {
    handleError(error, dispatch);
    dispatch({ type: LOADING_CART, payload: false });
  }
};

export const createOrder = (data) => async (dispatch) => {
  dispatch({ type: CREATING_CART, payload: true });
  try {
    const res = await http.post(`orders`, data);
    console.log(res.data);
    dispatch({ type: CREATE_ORDER_SUCCESS, payload: res.data });
    dispatch({ type: CREATING_CART, payload: false });
  } catch (error) {
    handleError(error, dispatch);
    dispatch({ type: CREATING_CART, payload: false });
  }
};

export const removeItemFromCart = (productId) => async (dispatch) => {
  dispatch({ type: REMOVING_FROM_CART, payload: productId });
  try {
    await http.delete(`cart/${productId}`);
    dispatch({ type: REMOVE_FROM_CART, payload: productId });
    dispatch({ type: REMOVING_FROM_CART, payload: "" });
    dispatch(getCart());
  } catch (error) {
    handleError(error, dispatch);
    dispatch({ type: REMOVING_FROM_CART, payload: "" });
  }
};

export const resetCart = () => (dispatch) => {
  dispatch({
    type: RESET_CART,
  });
};
