import React, { useEffect } from "react";
import { ColorSwatchIcon, TrashIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  getAllProducts,
  setProductAvailability,
} from "redux/action/adminActions";
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";

const ProductList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { gettingProducts, allProducts: products } = useSelector(({ admin }) => admin);

  useEffect(() => {
    if (!gettingProducts && !products.products) dispatch(getAllProducts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {products?.products?.map((product, index) => (
        <li
          key={index}
          className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
        >
          <div className="flex flex-1 flex-col p-8">
            <img
              className="mx-auto h-32 w-32 flex-shrink-0 rounded-full"
              src={product.mainImage}
              alt={product.name}
            />
            <h3 className="mt-6 text-sm font-medium text-gray-900">
              {product.name}
            </h3>
            <div className="flex">
              {product?.colors?.map((color) => (
                <div
                  key={color.name}
                  value={color.code}
                  className="relative mt-2 p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none"
                >
                  <span
                    style={{ backgroundColor: color.code }}
                    aria-hidden="true"
                    className="h-4 w-4 border border-black border-opacity-10 rounded-full"
                  />
                </div>
              ))}
            </div>
            <div>{product.price}</div>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="flex w-0 flex-1">
                <button
                  onClick={() => navigate(`/admin/editProduct/${product._id}`)}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <ColorSwatchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Modify
                </button>
              </div>
              <div className="-ml-px flex w-0 flex-1">
                <button
                  onClick={() =>
                    dispatch(
                      setProductAvailability(product._id, {
                        availabile: !product.availabile,
                      })
                    )
                  }
                  className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  {product.available ? (
                    <span className="text-emerald-400 flex">
                      <CheckCircleIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                      Available
                    </span>
                  ) : (
                    <span className="text-gray-400 flex">
                      <MinusCircleIcon
                        className="h-5 w-5 "
                        aria-hidden="true"
                      />
                      Not available
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="">
              <div className="flex flex-1">
                <button
                  onClick={() => dispatch(deleteProduct(product._id))}
                  className="relative text-red-500 inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold"
                >
                  <TrashIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                  Delete
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ProductList;
