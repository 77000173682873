import http from "plugin/http.client";
import {
  FETCH_PRODUCT,
  GETTING_PRODUCTS,
  GET_PRODUCTS,
  GET_SINGLE_PRODUCT,
} from "redux/reducer/product";
import { handleError } from "utils/handleError";

export const getProducts = () => async (dispatch) => {
  dispatch({ type: GETTING_PRODUCTS, payload: true });
  try {
    const res = await http.get("/products", { params: { limit: 100, available: true }});
    localStorage.setItem("products", JSON.stringify(res.data));
    dispatch({ type: GET_PRODUCTS, payload: res.data });
    dispatch({ type: GETTING_PRODUCTS, payload: false });
  } catch (error) {
    dispatch({ type: GETTING_PRODUCTS, payload: false });
    handleError(error, dispatch);
  }
};

export const getSingleProduct = (id) => async (dispatch) => {
  if (localStorage.getItem("products"))
    return dispatch({ type: GET_SINGLE_PRODUCT, payload: id });
  try {
    const res = await http.get(`/products/${id}`);
    dispatch({ type: FETCH_PRODUCT, payload: res.data.product });
  } catch (error) {
    handleError(error, dispatch);
  }
};
