import { useState } from "react";
import { useAppContext } from "../../../contexts/appcontext";
import GoogleIcon from "assets/icons/Google";
import { useGoogleLogin } from "@react-oauth/google";
import Modal from "components/basic/Modal";
import { getUser, logout } from "redux/action/userAction";
import { useDispatch, useSelector } from "react-redux";
import { getCartOnLogin } from "redux/action/cartAction";
import { handleError } from "utils/handleError";

const SignIn = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector(({ user }) => user);
  const { tempCart } = useSelector(({ cart }) => cart);

  const callback = (user) => {
    // send temporal cart to BE
    if (tempCart.products && tempCart.products[0]) {
      dispatch(getCartOnLogin(tempCart));
    }

    setOpenSignin(false);
  };

  const stopLoading = () => {
    setIsLoading(false);
  };

  const authenticateUser = useGoogleLogin({
    onSuccess: async (response) => {
      setIsLoading(true);
      dispatch(getUser({ auth_code: response.code }, callback, stopLoading));
    },
    onError: (error) => handleError(error),
    flow: "auth-code",
  });

  const { openSignin, setOpenSignin } = useAppContext();

  return (
    <Modal open={openSignin} setOpen={setOpenSignin}>
      {isLoggedIn ? (
        <div className="mx-auto max-w-2xl h-full overflow-y-scroll px-4 pb-16 sm:px-6 lg:max-w-7xl lg:gap-x-8 lg:px-8 lg:pt-4 lg:pb-24">
          <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900">
            Log Out
          </h2>
          <p className="mt-2 text-center text-sm font-medium text-gray-900">
            Come back any time{" "}
            <span role="img" alt="smiley face">
              😀
            </span>
          </p>
          <button
            type="button"
            onClick={() => dispatch(logout())}
            className="mt-10 flex w-full items-center justify-center rounded-md border border-teal-900 bg-white py-3 px-8 text-base font-medium text-teal-900 focus:outline-none focus:ring-2 focus:ring-[#de5249] focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          >
            Log Out
          </button>
        </div>
      ) : (
        <div className="mx-auto max-w-2xl h-full overflow-y-scroll px-4 pb-16 sm:px-6 lg:max-w-7xl lg:gap-x-8 lg:px-8 lg:pt-4 lg:pb-24">
          <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900">
            Create Account or Sign in
          </h2>
          <p className="mt-2 text-center text-sm font-medium text-gray-900">
            If you're new, we will create an account for you. Otherwise, welcome
            back! Read our{" "}
            <span className="underline">Privacy Policy here</span>
          </p>
          <button
            type="button"
            onClick={() => authenticateUser()}
            disabled={isLoading}
            className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-[#de5246] py-3 px-8 text-base font-medium text-white hover:bg-[#b34335] focus:outline-none focus:ring-2 focus:ring-[#de5249] focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          >
            {isLoading ? (
              <span className="ml-2">Please wait...</span>
            ) : (
              <>
                <GoogleIcon width="20px" />
                <span className="ml-2">Continue With Google</span>
              </>
            )}
          </button>
        </div>
      )}
    </Modal>
  );
};

export default SignIn;
