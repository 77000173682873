/* eslint-disable no-undef */
import axios from "axios";

const token = localStorage.getItem("token");

const http = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
});

if (token) {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

http.defaults.headers.common["x-api-key"] = `${process.env.REACT_APP_ACCESS_TOKEN}`;

http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.log({ error });
    if (error?.response?.status === 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("isLoggedIn");
      window.location.replace("/");
    }
    return Promise.reject(error);
  }
);

export default http;
