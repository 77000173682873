import React from "react";

const Text = ({
  label,
  name,
  onChange,
  value,
  error,
  disabled,
}) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <InputField
        disabled={disabled}
        onChange={onChange}
        name={name}
        value={value}
        error={error}
      />
    </div>
  );
};

export const InputField = ({
  disabled,
  onChange,
  name,
  value,
  error,
}) => {
  return (
    <div className="mt-1 sm:mt-0 sm:col-span-2">
      <input
        disabled={disabled}
        onChange={onChange}
        type="text"
        name={name}
        id={name}
        value={value}
        className="max-w-lg block w-full shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
      />
      {error && <p className="text-xs text-red-600 mt-1">{error}</p>}
    </div>
  );
};

export default Text;
