export const ADMIN_GET_PRODUCTS = "ADMIN_GET_PRODUCTS";
export const ADMIN_ADD_PRODUCT = "ADMIN_ADD_PRODUCTS";
export const ADMIN_DELETE_PRODUCTS = "ADMIN_DELETE_PRODUCTS";
export const ADMIN_GET_ORDERS = "ADMIN_GET_ORDERS";
export const SET_LOADING_ORDERS = "SET_LOADING_ORDERS";
export const ADMIN_EDIT_PRODUCT = "ADMIN_EDIT_PRODUCT";
export const SET_PRODUCT_AVAILABILITY = "SET_PRODUCT_AVAILABILITY";
export const ADMIN_MODIFY_PRODUCT = "ADMIN_MODIFY_PRODUCT";
export const GETTING_ALL_PRODUCTS = "GETTING_ALL_PRODUCTS";
export const ADMIN_GET_SINGLE_PRODUCT = "ADMIN_GET_SINGLE_PRODUCT";
export const ADMIN_FETCH_PRODUCT = "ADMIN_FETCH_PRODUCT";

const initialState = {
  loadingOrders: false,
  orders: [],
  allProducts: !!localStorage.getItem("allProducts")
    ? JSON.parse(localStorage.getItem("allProducts"))
    : [],
};

const admin = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING_ORDERS:
      return {
        ...state,
        loadingOrders: payload,
      };
    case ADMIN_GET_ORDERS:
      return {
        ...state,
        orders: payload,
        loadingOrders: false,
      };
    case GETTING_ALL_PRODUCTS:
      return {
        ...state,
        gettingProducts: payload,
      };

    case ADMIN_GET_PRODUCTS:
      return {
        ...state,
        allProducts: payload,
      };
    case ADMIN_GET_SINGLE_PRODUCT: {
      const { allProducts } = state;

      const singleProductArray = allProducts?.products.filter(
        (product) => product._id === payload
      );

      return {
        ...state,
        singleProduct: singleProductArray[0],
      };
    }
    case ADMIN_MODIFY_PRODUCT: {
      const { allProducts } = state;
      const { id, data } = payload;
      const productArray = allProducts?.products.map((product) => {
        if (product._id === id) {
          return data;
        }
        return product;
      });

      const productObject = {
        ...state.allProducts,
        products: productArray,
      };

      localStorage.setItem("allProducts", JSON.stringify(productObject));

      return {
        ...state,
        allProducts: productObject,
      };
    }

    case ADMIN_ADD_PRODUCT: {
      const { allProducts } = state;
      const productArray = [...allProducts?.products, payload];

      const productObject = {
        ...state.allProducts,
        products: productArray,
      };

      localStorage.setItem("allProducts", JSON.stringify(productObject));

      return {
        ...state,
        allProducts: productObject,
      };
    }

    case ADMIN_DELETE_PRODUCTS: {
      const productArray = state.allProducts?.products.filter(
        (product) => product._id !== payload
      );

      const productObject = {
        ...state.allProducts,
        products: productArray,
      };

      localStorage.setItem("allProducts", JSON.stringify(productObject));

      return {
        ...state,
        allProducts: productObject,
      };
    }

    case ADMIN_FETCH_PRODUCT:
      return {
        ...state,
        singleProduct: payload,
      };
    default:
      return state;
  }
};

export default admin;
