import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const Home = () => {
  const images = [
    "https://res.cloudinary.com/db7anhor6/image/upload/v1698612996/SA3_ukqwdx.jpg",
    "https://res.cloudinary.com/db7anhor6/image/upload/v1698612998/SA4_evnxl8.jpg",
    "https://res.cloudinary.com/db7anhor6/image/upload/v1698612988/SA8_gtsjpl.jpg",
    "https://res.cloudinary.com/db7anhor6/image/upload/v1698612990/SA9_gmuzwc.jpg",
    "https://res.cloudinary.com/db7anhor6/image/upload/v1698612992/SA5_rlalor.jpg",
    "https://res.cloudinary.com/db7anhor6/image/upload/v1698612991/SA10_ienrfk.jpg",
    "https://res.cloudinary.com/db7anhor6/image/upload/v1698612980/SA7_urfzsl.jpg",
    "https://res.cloudinary.com/db7anhor6/image/upload/v1698612984/SA2_cl1bz0.jpg",
    "https://res.cloudinary.com/db7anhor6/image/upload/v1698612992/SA6_grxjdm.jpg",
    "https://res.cloudinary.com/db7anhor6/image/upload/v1698612985/SA1_xr09ci.jpg",
  ];

  const responsiveSettings = [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ];

  return (
    <div className="h-screen w-screen overflow-hidden bg-teal-800">
    <Slide
      slidesToScroll={1}
      slidesToShow={1}
      responsive={responsiveSettings}
      arrows={true}
    >
      {images.map((image, index) => (
        <div className="each-slide-effect" key={index}>
          <div
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              margin: "0 0.2rem",
            }}
            className="h-screen"
          ></div>
        </div>
      ))}
    </Slide>
  </div>
  );
};

export default Home;
