export const GET_USER = "GET_USER";
export const LOGOUT = "LOGOUT";

const initialState = {
  isLoggedIn: localStorage.getItem("isLoggedIn")
  ? localStorage.getItem("isLoggedIn")
  : false,
  user: !!localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {},
};

const user = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER:
      return {
        ...state,
        user: payload,
        isLoggedIn: true,
      };
      case LOGOUT:
        return {
          ...state,
          user: {},
          isLoggedIn: false,
        };
      
    default:
      return state;
  }
};

export default user;
