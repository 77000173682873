import React from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFeomTempCart,
  removeItemFromCart,
} from "redux/action/cartAction";

const CartProducts = ({ currentCart, checkingOut }) => {
  const dispatch = useDispatch();
  const { removing } = useSelector(({ cart }) => cart);

  return (
    <div className="mt-8">
      <div className="flow-root">
        <ul className="-my-6 divide-y divide-gray-200">
          {currentCart.map((product, index) => (
            <li key={index} className="flex py-6">
              <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                <img
                  src={
                    product?.productDetails?.mainImage ||
                    product?.product?.mainImage
                  }
                  alt={product?.productDetails?.name || product?.product?.name}
                  className="h-full w-full object-cover object-center"
                />
              </div>

              <div className="ml-4 flex flex-1 flex-col">
                <div>
                  <div className="flex justify-between text-base font-medium text-gray-900">
                    <h3>
                      {product?.productDetails?.name || product?.product?.name}
                    </h3>
                    <p className="ml-4">
                      {product.price || product?.product?.price}
                    </p>
                  </div>
                  <div
                    style={{
                      backgroundColor: product.color || product?.product?.color,
                    }}
                    className="relative w-7 h-7 my-2 flex border border-gray-500 shadow-2xl cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
                  />
                </div>
                <div className="flex flex-1 items-end justify-between text-sm">
                  <p className="text-gray-500">Qty {product.quantity  || product?.product?.quantity}</p>

                  <div className="flex">
                    <button
                      type="button"
                      className="font-medium text-emerald-600 hover:text-emerald-500"
                      onClick={() =>
                        checkingOut
                          ? dispatch(removeItemFromCart(product._id))
                          : dispatch(removeFeomTempCart(product.index))
                      }
                      disabled={removing === product._id}
                    >
                      {removing === product._id ? <ThreeDots height="20" width="20" />: "Remove" }
                    </button>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CartProducts;
